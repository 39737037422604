import * as React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout.js"
import Seo from "../components/seo.js"

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);


const BlogPostPage = ({ data, pageContext, location }) => {

  return (
        <Layout>
        <Seo
          pagetitle={data.microcmsBlog.title}
          pagedesc="desc"
          pagepath={location.pathname}
        />
      <div>
        <div className="mainTtlBg">
          <div className="mainTtlArea container">
            <p>ヘッドレスCMSブログ<span>BLOG</span></p>
          </div>{/* /mainphArea */}
        </div>{/* /mainphAreabg */}
      {/* パン屑 start */}
      <div className="breadArea">
        <ul className="breadLt container clearfix" itemScope itemType="https://schema.org/BreadcrumbList">
          <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
            <Link to="https://jamstack-sg.com/" itemProp="item"><span itemProp="name">ヘッドレスCMS特設サイト</span></Link>
            <meta itemProp="position" content={1} />
          </li>
          <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
            <Link to="https://jamstack-sg.com/blog/" itemProp="item"><span itemProp="name">ブログ</span></Link>
            <meta itemProp="position" content={2} />
          </li>
          <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
            <Link to={`https://jamstack-sg.com${location.pathname}`} itemProp="item"><span itemProp="name">{data.microcmsBlog.title}</span></Link>
            <meta itemProp="position" content={3} />
          </li>
        </ul>
      </div>
      {/* パン屑 end */}
        <div className="blogPost containerS">
        <div className="eyecatch">
          <img src={data.microcmsBlog.eyecatch.url} alt="" />
        </div>
        <article>
            <h1>{data.microcmsBlog.title}</h1>
            <ul className="entryMeta clearfix">
              <li class="time"><time dateTime={dayjs.utc(data.microcmsBlog.publishDate).tz('Asia/Tokyo').format('YYYY-MM-DD')}>{dayjs.utc(data.microcmsBlog.publishDate).tz('Asia/Tokyo').format('YYYY年M月D日')}</time></li>
              <li class="cate">
                <ul>
                  {data.microcmsBlog.category.map(cat => (
                    <li className={cat.categorySlug} key={cat.id}>{cat.category}</li>
                  ))}
                </ul>
              </li>
            </ul>
            <div className="postbody"
              dangerouslySetInnerHTML={{ __html: data.microcmsBlog.content }}
              >
            </div>
            <ul className="postlink clearfix">
              {pageContext.previous && (
                <li className="prev">
                  <Link to={`/blog/${pageContext.previous.slug}/`} rel="prev">
                    <span>{pageContext.previous.title}</span>
                  </Link>
                </li>
              )}
              {pageContext.next && (
                <li className="next">
                  <Link to={`/blog/${pageContext.next.slug}/`} rel="next">
                    <span>{pageContext.next.title}</span>
                  </Link>
                </li>
              )}
            </ul>
        </article>
        </div>
      </div>
        </Layout>
  )
}

export const query = graphql`
query($id: String!) {
  microcmsBlog(id: {eq:$id}) {
    content
    slug
    title
    category {
      categorySlug
      category
      id
    }
    eyecatch {
      height
      url
      width
    }
    publishDate
  }
}`

export default BlogPostPage